import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { kebabCase } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { mobile, tabletL } from 'styles/responsive';
import Template, { TemplateType } from 'components/templates/MainTemplate';
import HorizPostItem from 'components/molecules/items/HorizPostItem';
import Footer from 'components/organisms/footer/Footer';
import { PostListItemType } from 'types/PostItem.types';
import MainContent from 'components/molecules/container/MainContent';
import Header from 'components/organisms/header/Header';
import MainContainer from 'components/molecules/container/MainContainer';

interface BlogTemplateType extends TemplateType {
  data: {
    allMarkdownRemark: {
      edges: PostListItemType[];
    };
    file: {
      publicURL: string;
    };
  };
  pageContext: {
    category: string;
    image: string;
    totalCount: number;
    numPages: number;
    currentPage: number;
  };
}

const CategoryTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px 0;

  @media (max-width: ${tabletL}) {
    gap: 12px;
    margin: 0;
  }

  @media (max-width: ${mobile}) {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
`;

const CategoryIcon = styled(GatsbyImage)`
  width: 52px;
  height: 52px;

  @media (max-width: ${tabletL}) {
    width: 48px;
    height: 48px;
  }

  @media (max-width: ${mobile}) {
    width: 96px;
    height: 96px;
  }
`;

const CategoryTitle = styled.div`
  display: flex;
  color: var(--post-point-text);
  font-size: 28px;
  font-weight: var(--fw-m);

  @media (max-width: ${tabletL}) {
    font-size: 24px;
  }

  @media (max-width: ${mobile}) {
    justify-content: center;
    width: 100%;
    margin: 12px 0;
  }
`;

const PostCount = styled.div`
  margin-left: 16px;
  color: var(--point-color);
  font-size: 24px;
  line-height: 1.4;

  @media (max-width: ${tabletL}) {
    margin-left: 12px;
    font-size: 20px;
  }

  @media (max-width: ${mobile}) {
    margin-left: 8px;
    line-height: 1.5;
  }
`;

const PostListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (max-width: ${mobile}) {
    margin: 0 auto;
    padding: 0;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 100px;

  .fa-arrow-left {
    margin-right: 10px;
  }
  .fa-arrow-right {
    margin-left: 10px;
  }

  @media (max-width: ${mobile}) {
    margin-top: 50px;
    font-size: 12px;

    .fa-arrow-left {
      margin-right: 5px;
    }
    .fa-arrow-right {
      margin-left: 5px;
    }
  }
`;

const PagesNum = styled.div`
  a {
    margin: 0 5px;
    padding: 10px 15px;
    border-radius: 50%;
  }

  @media (max-width: ${mobile}) {
    a {
      margin: 0 2.5px;
      padding: 5px 10px;
    }
  }
`;

export default function BlogTemplate({
  data,
  pageContext: { category, totalCount, numPages, currentPage },
}: BlogTemplateType) {
  const { edges } = data.allMarkdownRemark;

  const categoryImage = getImage(data.file);
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? `/categories/${kebabCase(category)}/`
      : `/categories/${kebabCase(category)}/${currentPage - 1}`;
  const nextPage = `/categories/${kebabCase(category)}/${currentPage + 1}`;

  return (
    <Template>
      <MainContainer>
        <Header />
        <MainContent>
          <CategoryTitleWrapper>
            <CategoryIcon image={categoryImage} alt={category} />
            <CategoryTitle>
              {category.replace('-', '/')}
              <PostCount>({totalCount})</PostCount>
            </CategoryTitle>
          </CategoryTitleWrapper>
          <PostListWrapper>
            {edges.map(
              ({
                node: {
                  fields: { slug },
                  frontmatter,
                },
              }: PostListItemType) => (
                <HorizPostItem
                  {...frontmatter}
                  link={slug}
                  key={frontmatter.title}
                />
              ),
            )}
            <PaginationWrapper>
              <Link
                to={prevPage}
                rel="prev"
                style={{ visibility: `${isFirst ? 'hidden' : 'visible'}` }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                Prev Page
              </Link>
              <PagesNum>
                {Array.from({ length: numPages }, (_, i) => (
                  <Link
                    key={`pageNum${i + 1}`}
                    to={`/categories/${kebabCase(category)}/${
                      i === 0 ? `` : i + 1
                    }`}
                    activeStyle={{ background: `var(--point-bg)` }}
                  >
                    {i + 1}
                  </Link>
                ))}
              </PagesNum>
              <Link
                to={nextPage}
                rel="next"
                style={{ visibility: `${isLast ? 'hidden' : 'visible'}` }}
              >
                Next Page
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </PaginationWrapper>
          </PostListWrapper>
          <Footer />
        </MainContent>
      </MainContainer>
    </Template>
  );
}

export const categoryQuery = graphql`
  query Category($category: String, $image: String, $skip: Int, $limit: Int) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            summary
            date(formatString: "YYYY.MM.DD")
            tags
            categories
            thumbnail {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
              publicURL
            }
          }
        }
      }
    }
    file(name: { eq: $image }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
      publicURL
    }
  }
`;
