import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { mobile, tabletL } from 'styles/responsive';

interface MainContainerType {
  children?: ReactNode;
}

const Container = styled.div`
  position: relative;
  min-height: 100vh;
  margin: 30px 30px 30px 0;
  padding: 60px 0;
  border: var(--main-content-border);
  border-radius: 10px;
  background: var(--main-content-bg);
  box-shadow: var(--main-content-shadow);

  @media (max-width: ${tabletL}) {
    padding: 100px 0;
  }

  @media (max-width: ${mobile}) {
    margin: 0;
    padding: 0;
    border-radius: 0;
  }
`;

export default function MainContainer({ children }: MainContainerType) {
  return <Container>{children}</Container>;
}
