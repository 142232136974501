import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { mobile, tabletL } from 'styles/responsive';

interface MainContentType {
  children?: ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 75%;
  margin: 0 auto;
  padding: 20px 0 100px 0;

  @media (max-width: ${tabletL}) {
    padding: 0;
  }

  @media (max-width: ${mobile}) {
    gap: 24px;
    width: 100%;
    margin: 0;
    padding: 20px;
  }
`;

export default function MainContent({ children }: MainContentType) {
  return <Container>{children}</Container>;
}
