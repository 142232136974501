import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { mobile, tabletL } from 'styles/responsive';
import { PostFrontmatterType } from 'types/PostItem.types';
import PostItemContainer from '../container/PostItemContainer';
import PostSummary from 'components/atoms/Text/PostSummary';
import PostTitle from 'components/atoms/Text/PostTitle';
import PostTagList from '../../organisms/post/PostTagList';
import PostDate from 'components/atoms/Text/PostDate';

interface HorizPostItemType extends PostFrontmatterType {
  link: string;
}

const ThumbnailImage = styled(GatsbyImage)`
  width: 40%;
  height: 100%;
  border-radius: 8px;

  @media (max-width: ${tabletL}) {
    width: 50%;
  }

  @media (max-width: ${mobile}) {
    display: none;
  }
`;

const PostItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;
  padding: 0 16px 0 24px;

  @media (max-width: ${mobile}) {
    gap: 8px;
    padding: 8px;
  }
`;

const Category = styled.div`
  color: var(--point-color);
  font-size: var(--fs-l);
  font-weight: 600;

  @media (max-width: ${mobile}) {
    font-size: 12px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AddInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export default function HorizPostItem({
  link,
  title,
  date,
  tags,
  categories,
  summary,
  thumbnail: {
    childImageSharp: { gatsbyImageData },
  },
}: HorizPostItemType) {
  return (
    <PostItemContainer link={link} direction={`horizontal`}>
      <ThumbnailImage image={gatsbyImageData} alt="Post Thumbnail Image" />
      <PostItemContent>
        <Category>{categories}</Category>
        <Content>
          <PostTitle
            category={categories}
            title={title}
            direction={`horizontal`}
          />
          <PostSummary summary={summary} />
        </Content>
        <AddInfo>
          <PostTagList tags={tags} direction={`horizontal`} />
          <PostDate icon={faClock} date={date} />
        </AddInfo>
      </PostItemContent>
    </PostItemContainer>
  );
}
