import styled from '@emotion/styled';
import { tabletL } from 'styles/responsive';
import Search from 'components/organisms/header/Search';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 75%;
  margin: 0 auto;

  @media (max-width: ${tabletL}) {
    display: none;
  }
`;

export default function Header() {
  return (
    <HeaderContainer>
      <Search />
    </HeaderContainer>
  );
}
